<template>
  <v-card class="pa-6" color="foreground">
    <v-card-title class="px-0 pt-0">
      <v-btn class="rounded-lg mr-3" icon @click="navigation(`/checkout`)">
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>

      Dados Pessoais
    </v-card-title>

    <!-- personal data -->
    <PersonalData />

    <h1 class="mb-4 text-h6">Detalhes do cartão</h1>

    <!-- awaiting -->
    <v-list-item v-if="awaitingMethods.includes('CARD')" class="accent">
      <v-list-item-title class="text-center secondary_text--text">
        Transação pendente,
        <a @click="continueTransaction()">clique para continuar</a>
      </v-list-item-title>
    </v-list-item>

    <!-- form -->
    <v-form
      v-else-if="!lockedMethods['CARD']"
      v-model="valid"
      ref="form"
      autocomplete="off"
    >
      <!-- number / brand -->
      <v-row>
        <v-col cols="7">
          <v-text-field
            v-model="form.number"
            v-mask="cardMask"
            id="card-number"
            label="Número do cartão"
            background-color="accent"
            :rules="ruleNumber"
            autofocus
            outlined
            @paste="handlePaste($event)"
          >
            <template v-slot:append>
              <v-icon color="secondary"> mdi-credit-card-outline </v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="5">
          <v-select
            v-model="form.brand"
            :items="brands"
            label="Bandeira"
            item-text="label"
            item-value="value"
            background-color="accent"
            :rules="rule"
            single-line
            outlined
          >
            <template v-slot:[`item`]="{ item }">
              <v-img
                :src="require(`@/assets/flags/${item.value || 'DEFAULT'}.png`)"
                class="mr-2"
                max-width="32"
                contain
              />

              {{ item.label }}
            </template>
          </v-select>
        </v-col>
      </v-row>

      <!-- holder -->
      <v-text-field
        v-model="form.holder"
        label="Nome do títular"
        background-color="accent"
        :rules="rule"
        outlined
        @keypress="notNumber()"
      >
        <template v-slot:append>
          <v-icon color="secondary"> mdi-account-outline </v-icon>
        </template>
      </v-text-field>

      <!-- exp date / cvv -->
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model="form.expDate"
            v-mask="'##/##'"
            label="Validade"
            placeholder="MM/AA"
            background-color="accent"
            :rules="ruleExp"
            outlined
          >
            <template v-slot:append>
              <v-icon color="secondary"> mdi-calendar-outline </v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="6">
          <v-text-field
            v-model="form.cvv"
            v-mask="'####'"
            label="CVC"
            placeholder="####"
            background-color="accent"
            :rules="ruleCvv"
            outlined
            @focus="emitFlip(true)"
            @blur="emitFlip(false)"
          >
            <template v-slot:append>
              <v-icon color="secondary"> mdi-lock-outline </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- owner -->
      <v-text-field
        v-model="form.ownerDocument"
        v-mask="'###.###.###-##'"
        label="CPF do títular"
        placeholder="###.###.###-##"
        background-color="accent"
        :rules="ruleDocument"
        outlined
      >
        <template v-slot:append>
          <v-icon color="secondary"> mdi-card-bulleted-outline </v-icon>
        </template>
      </v-text-field>

      <v-btn color="primary" block large @click="submit()">
        Concluir Pagamento
      </v-btn>
    </v-form>

    <!-- unavaiable -->
    <v-list-item v-else class="accent">
      <v-list-item-title class="text-center secondary_text--text">
        Serviço indisponível para cartão de crédito
      </v-list-item-title>
    </v-list-item>

    <loader-hover v-if="loading" />
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { creditCard } from "@/services/payment";
import { getTransaction } from "@/services/checkout";
import { displayAlert, upperCase, notNumber } from "@/utils";
import { mask } from "vue-the-mask";
import PersonalData from "@/components/checkout/PersonalData";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      brands: [
        { label: "Visa", value: "VISA" },
        { label: "MasterCard", value: "MASTER" },
        { label: "Elo", value: "ELO" },
        { label: "Amex", value: "AMEX" },
        { label: "Diners Club", value: "DINERS" },
      ],
      cardMask: "################",
      form: {
        brand: "",
        number: "",
        holder: "",
        expDate: "",
        cvv: "",
        ownerDocument: "",
      },
    };
  },

  components: { PersonalData },

  directives: { mask },

  beforeMount() {
    // prevent access this route with transaction performed
    if (this.transactionPerformed && this.jwtDecoded) {
      const method = this.jwtDecoded.transaction.method;

      if (method != "CARD") {
        return this.$router.push({
          path: `/checkout/payment/${method.toLowerCase()}`,
        });
      } else {
        return this.$router.push({
          path: "/checkout/summary",
        });
      }
    }
  },

  watch: {
    ["form.brand"](value) {
      this.emitCard();
      this.handleMask();
    },

    ["form.number"](value) {
      this.emitCard();

      this.detectCardType(value.replaceAll(/\s/g, ""));
      if (value.length < 1) this.form.brand = "";
    },

    ["form.holder"](value) {
      this.form.holder = upperCase(value);
      this.emitCard();
    },

    ["form.expDate"]() {
      this.emitCard();
    },

    ["form.cvv"]() {
      this.emitCard();
    },
  },

  computed: {
    ...mapState([
      "jwtDecoded",
      "current",
      "transactionPerformed",
      "awaitingMethods",
      "lockedMethods",
    ]),

    rule() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    ruleNumber() {
      let size =
        this.form.brand === "AMEX" || this.form.brand === "DINERS" ? 16 : 19;

      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length >= size || "Cartão Inválido",
      ];
    },

    ruleExp() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length === 5 || "Validade Inválida",
      ];
    },

    ruleCvv() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length >= 3 || "CVC Inválido",
      ];
    },

    ruleDocument() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length === 14 || "CPF Inválido",
      ];
    },
  },

  methods: {
    ...mapActions(["setCurrent", "setTransactionPerformed"]),

    // submit card
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          brand: this.form.brand,
          number: this.form.number.replaceAll(/\s/g, ""),
          holder: this.form.holder,
          expDate: this.form.expDate.replace("/", "/20"),
          cvv: this.form.cvv,
          ownerDocument: this.form.ownerDocument
            .replaceAll(".", "")
            .replace("-", ""),
          transactionId: this.jwtDecoded.transaction.ref,
          isDebit: false,
        };

        await creditCard(payload).then((res) => {
          this.displayAlert("Pagamento efetuado com sucesso");

          this.setTransactionPerformed(true);

          // get current transaction to commit
          this.getCurrentTransaction();

          // navigation to review
          this.navigation(`/checkout/summary/${res.body.id}`);
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);

        this.refusedCard();
      } finally {
        this.loading = false;
      }
    },

    // get and commit current transaction
    async getCurrentTransaction() {
      try {
        const payload = {
          ref: this.jwtDecoded.transaction.ref,
          document: this.jwtDecoded.customer.document,
        };

        await getTransaction(payload).then((res) => {
          this.setCurrent(res.body); // commit on storage
        });
      } catch (err) {
        console.log(err);
      }
    },

    // handle refused card
    async refusedCard() {
      try {
        const payload = {
          ref: this.jwtDecoded.transaction.ref,
          document: this.jwtDecoded.customer.document,
        };

        await getTransaction(payload).then((res) => {
          const transaction = res.body;

          if (transaction.transaction.transactionStatusId === 3) {
            this.setCurrent(transaction);

            this.setTransactionPerformed(true);

            // navigation to review
            this.navigation(
              `/checkout/summary/${transaction.data.transactionId}`
            );
          }
        });
      } catch (err) {
        console.log(err);
      }
    },

    // detect brand
    detectCardType(number) {
      var re = {
        AMEX: /^3[47][0-9]{13}$/,
        DINERS: /^(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])/,
        ELO: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
        MASTER:
          /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
        VISA: /^4[0-9]{15}$/,
      };

      for (var key in re) {
        if (re[key].test(number)) {
          this.form.brand = key;

          // focus end of input with mask
          const input = document.getElementById("card-number");
          const pos = input.value.length;

          window.setTimeout(function () {
            input.setSelectionRange(pos, pos);
          }, 0);

          return true;
        }
      }
    },

    // set mask os number input
    handleMask() {
      if (this.form.brand === "AMEX" || this.form.brand === "DINERS")
        this.cardMask = "#### ###### #####";
      else if (
        this.form.brand === "ELO" ||
        this.form.brand === "MASTER" ||
        this.form.brand === "VISA"
      )
        this.cardMask = "#### #### #### ####";
      else this.cardMask = "################";
    },

    // handle when copy paster input of card
    handlePaste() {
      this.cardMask = "################";
      this.form.brand = "";

      setTimeout(() => {
        //pendent get copy clipboard value

        this.detectCardType(this.form.number.replaceAll(/\s/g, ""));
        this.handleMask();
      }, 100);
    },

    // emit input card
    emitCard() {
      const payload = {
        brand: this.form.brand,
        number: this.form.number,
        holder: this.form.holder,
        expDate: this.form.expDate,
        cvv: this.form.cvv,
      };

      this.$root.$emit("input-card", payload);
    },

    // emit flip card
    emitFlip(payload) {
      this.$root.$emit("flip-card", payload);
    },

    // continue current transactions
    continueTransaction() {
      // continue transaction
      const id = this.jwtDecoded.awaitingTransactions.find(
        (e) => e.paymentMethod === "CARD"
      ).id;

      this.$root.$emit("continue-transaction", id);
    },

    // router push
    navigation(path) {
      this.$router.push({ path: path });
    },

    displayAlert,

    notNumber,
  },
};
</script>

<style></style>
